import { mapGetters } from "vuex";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import ApiService from "@/core/services/api.service";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ConfirmDialog from "@/view/pages/ConfirmDialog";
export default {
  props: {
    modal: {
      default: false
    },
    value: Boolean
  },

  name: "import-csv",
  data() {
    return {
      alertSuccess: false,
      alertError: false,
      valid: false,
      isSaving: false,
      request: {
        shopId: null,
        delimiter: "",
        orderIds: "",
        fileBase64: ""
      },
      shops: [],
      search: "",
      shopsIsLoading: false,
      requiredRule: [v => !!v || "Field is required"]
    };
  },
  created() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Create shop products import request" }]);
    this.searchShops();
  },
  components: {
    ConfirmDialog
  },
  mounted: function () {
    if(this.alertSuccess || this.alertError){
      this.hide_alert();
    }
  },
  watch: {
    alertSuccess(new_val){
      if(new_val){
        setTimeout(()=>{this.alertSuccess=false},3000)
      }
    },
    alertError(new_val){
      if(new_val){
        setTimeout(()=>{this.alertError=false},3000)
      }
    }
  },
  methods: {
    validateAndSave(){
      if (this.$refs.form.validate()){
        this.sentRequestPopup(this.request);
      }
    },
    async sentRequestPopup(request) {
      if (
          await this.$refs.confirm.open(
              this.$i18n.t('common.confirm'),
              this.$i18n.t('pages.importCsv.sendRequestMessage') +
              " ?"
          )
      ) {
        this.sendRequest(request);
      }
    },
    clearForm() {
      this.$refs.form.reset();
    } ,
    previousState() {
      this.$router.go(-1);
    },
    endsWith(suffix, str) {
      return str.indexOf(suffix, str.length - suffix.length) !== -1;
    },
    paddingSize(value) {
      if (this.endsWith("==", value)) {
        return 2;
      }
      if (this.endsWith("=", value)) {
        return 1;
      }
      return 0;
    },
    size(value) {
      return (value.length / 4) * 3 - this.paddingSize(value);
    },
    byteSize(base64String) {
      return this.formatAsBytes(this.size(base64String));
    },
    formatAsBytes(size) {
      return size.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " bytes";
    },
    handleImage(event) {
      if (event && event.target.files && event.target.files[0]) {
        const file = event.target.files[0];
        // this.toBase64(file);
        this.toBase64(file, base64Data => {
          this.request.fileBase64 = base64Data;
        });
      }
    },
    toBase64(file, cb) {
      const fileReader = new FileReader();
      fileReader.onload = e => {
        const base64Data = e.target.result;
        cb(base64Data);
      };
      fileReader.readAsDataURL(file);
    },
    searchShops() {
      // Items have already been loaded
      if (this.shops.length > 0) return;

      // Items have already been requested
      if (this.shopsIsLoading) return;

      this.shopsIsLoading = true;
      ApiService.query("api/shops/dropdown-list")
        .then(res => {
          this.shops = res.data;
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.shopsIsLoading = false));
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      let target = event.target;
      if (!event.target.classList.contains("navi-link")) {
        target = event.target.closest(".navi-link");
      }

      const tab = target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".navi-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(target.getAttribute("data-tab"));

      // set current active tab
      target.classList.add("active");
    },
    sendRequest(request) {
      this.isSaving = true;
      this.loading = true;
        ApiService.post(`api/mq-sender/import-product-items`, request)
          .then(response => {
            this.$log.debug("Request sent: " + response);
            this.alertSuccess = true;
          })
          .catch(error => {
            this.$log.error("Error: ", error);
            this.errored = true;
            this.alertError = true;
          })
          .finally(() => {
            this.loading = false;
            this.isSaving = false;
            this.clearForm();
          });
    }
  },
  computed: {
    ...mapGetters(["currentUser"]),
    ...mapGetters(["currentUserPersonalInfo"]),
    show: {
      get() {
        return this.value;
      },
      set(value) {
        if (!value) {
          this.$emit("input", value);
        }
      }
    }
  }
};
